
@media (hover: none) and (pointer: coarse) {
  #sidebar .el-button:hover {
    background-color: var(--el-button-bg-color);
    border: var(--el-border);
    color: var(--el-button-text-color);
    outline: inherit;
  }
}

.leaflet-sidebar-infobutton {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 18px;
}
.leaflet-sidebar-infobutton a {
  color: #fff;
}
// using font-awesome
.gallery-item-hasgpscoord {
  position: absolute;
  bottom: 7px;
  right: 20px;
  // background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255 76 0);
  padding: 2px 4px;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 0 0 0 5px;
  transition: background-color 0.3s ease;
}
.gallery-item-hasgpscoord i {
  // color: rgb(0, 255, 165);
  width: 10px;
  height: 10px;
  position: relative;
  top: 0;
  right: 0;
  font-size: 20px;
}

#sidebar .photo-counter {
  text-decoration: none;
}
.adm-copy-helper .el-button {
  padding: 8px 8px;
}
.distance-link {
  text-decoration-style: dotted;
  text-underline-position: from-font;
}
.leaflet-bar {
  border-radius: 0px;
}
.leaflet-control-layers {
  border-radius: 2px;
}

.image-div {
  position: relative;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.component-sidebar .gallery-div .gallery-item2:hover .play-button-overlay {
  opacity: 1;
}
.gallery-div {
  padding: 4px;
}

.play-button {
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%; /* Make the button circular */
  width: 50px; /* Set width */
  height: 50px; /* Set height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3); /* Light background for contrast */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional shadow */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.play-button:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Change background on hover */
}

.sidebar-notes {
  text-align: justify;
}

.distance-popover .el-popover__title {
  text-align: center;
  font-weight: bold;
}
.dateacc-popover,
.distance-popover {
  text-align: center;
}

/* draw left/right button to openc close sidebar */
.leaflet-sidebar-control-buttons {
  display: inline-block;
  position: absolute;
  /* color: #f00; */
  background-color: #fff;
  z-index: 1999;
  top: 50%;
  margin-top: -23px;
  height: 46px;
  width: 23px;
  border-radius: 20px 0px 0px 20px;
  cursor: pointer;

  right: 1px;
  transition: all 500ms;
  box-shadow: 4px 4px 16px grey;
}

.sidebar-opened.leaflet-sidebar-control-buttons {
  right: 570px;
  transition: all 500ms;
  box-shadow: none;
}

.leaflet-sidebar-control-buttons .tabicon {
  display: block;
  top: 17px;
  border-top: 6px dashed transparent;
  border-bottom: 6px dashed transparent;
  position: absolute;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.sidebar-opened .tabicon {
  left: 9px;
  border-left: 8px solid #333;
}

.sidebar-closed .tabicon {
  left: 7px;
  border-right: 8px solid #333;
}

/* Hide tabs panel in the sidebar */
.collapsed.leaflet-sidebar {
  border: 0;
}

.leaflet-sidebar-right.collapsed ~ .leaflet-control-container .leaflet-right {
  right: 0;
}
.leaflet-sidebar.collapsed {
  width: 0px;
}
.leaflet-sidebar-header {
  background-color: #42a5f5;
  text-shadow: 1px 1px 2px #00000082;
  height: var(--app-menu-height);
  line-height: var(--app-menu-height);
}
.leaflet-sidebar-tabs > ul > li.active > a {
  background-color: #42a5f5;
}
.leaflet-sidebar-tabs > li,
.leaflet-sidebar-tabs > ul > li {
  height: var(--app-menu-height);
}
leaflet-sidebar-tabs > li > a,
.leaflet-sidebar-tabs > ul > li > a {
  line-height: var(--app-menu-height);
}

.placeimages h4 {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
}
.sidebar-image-group {
  margin-bottom: 15px;
}
hr.sidebar-image-divider {
  border: 0.5px solid #42a5f5;
  margin-top: 9px;
  width: 50%;
}
#sidebar-eventdate .acclevel-sidebar {
  margin-right: 5px;
  height: 13px;
  position: relative;
  bottom: -1px;
}
.sidebar-placeinfo-main {
  font-size: 14px;
  text-align: justify;
}
.sidebar-placeinfo-extra {
  font-size: 13px;
}

.sidebar-place-transport {
  padding-left: 10px;
  /* float: right; */
  font-style: normal;
  font-size: 1.9em;
  position: relative;
  bottom: -4px;
}

.sidebar-transport-change {
  padding: 0 5px;
  color: #000;
  position: relative;
  top: 2px;
}

.sidebar-place-goby-emoji {
  /* font-size: 1.6em; */
  font-size: 20px;
  line-height: 12px;
}
.sidebar-place-goby-emoji img {
  height: 28px;
  position: relative;
  top: 2.5px;
}
// .sidebar-place-goby-emoji img.horse {
// }

.leaflet-sidebar-pane.active > h1 {
  position: sticky;
  font-family: 'Noto Sans', sans-serif;
  top: 0;
  z-index: 2;
  font-size: 16px;
}

#sidebar-eventdate {
  font-family: sans-serif;
  font-style: italic;
  font-size: 0.9rem;
  clear: both;
}

.leaflet-sidebar-content {
  cursor: initial;
}

.place-hover-link {
  display: none;
}

h2:hover > .place-hover-link {
  display: inline;
}

.title-placeid {
  font-size: 0.8em;
  color: #666;
}

.leaflet-sidebar {
  z-index: 2000;
}

.component-sidebar .gallery-div .gallery-item {
  display: inline-block;
  vertical-align: text-top;
  width: 200px;
  /* padding: 5px; */
}

.image-div {
  aspect-ratio: 3/ 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 0;
}
.image-meta {
  padding: 0px 0 8px 8px;
  display: none;
}

.gallery-item2 .el-image img,
.img-responsive {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding: 0px 2px;
  /* max-height: 120px; */
  cursor: zoom-in;
  box-sizing: border-box;
  object-fit: contain;
}

.leaflet-sidebar-pane {
  /*  FIXME - отступ сделан для Игоря */
  padding: 10px 25px 0px 20px;
}

#sidebar-title {
  text-align: center;
}

.leaflet-control .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
}

/* @media (min-width: 1620px) {
  .leaflet-sidebar {
    width: 558px;
    max-width: 531px;
  }
} */

@media (min-width: 1400px) {
  .leaflet-sidebar {
    width: 560px;
    max-width: 560px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .leaflet-sidebar {
    width: 355px;
    max-width: 355px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 0px;
    /* right: none; */
    /* transition: none; */
    box-shadow: 4px 4px 16px grey;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 317px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 402px;
  }
}
@media (min-width: 1200px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 472px;
  }
}
@media (min-width: 1400px) {
  .sidebar-opened.leaflet-sidebar-control-buttons {
    right: 572px;
  }
}
@media print {
  .leaflet-sidebar-control-buttons {
    display: none;
  }
}

@media (min-width: 1400px) {
  .leaflet-sidebar-right ~ .leaflet-control-container .leaflet-right {
    right: 570px;
  }
}

@media (max-height: 500px) {
  .leaflet-left .leaflet-control:not(.leaflet-control-horizontal-stack) {
    margin-left: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
  }
  .leaflet-top.leaflet-left .leaflet-control {
    margin-top: 0px;
    /* border-top-right-radius: 0; */
    /* border-top-left-radius: 0; */
    border-radius: 0 !important;
  }
  .leaflet-touch .leaflet-bar a:first-child,
  .leaflet-touch .leaflet-bar a:last-child {
    border-radius: 0 !important;
  }
  .leaflet-control-container .leaflet-control-layers.leaflet-control {
    margin-top: 20px;
  }
  .leaflet-sidebar.leaflet-touch .leaflet-control-layers {
    border: none;
    border-radius: 0 !important;
  }
  .leaflet-sidebar.leaflet-touch .leaflet-control {
    border: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
    border-radius: 0 !important;
  }
  .leaflet-control-container .leaflet-left .leaflet-bar {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
  }
  .leaflet-control-layers {
    border: none;
    border-radius: 0 !important;
  }
  .leaflet-control-layers {
    border-left: none !important;
    border-top: 1px solid #a3a3a3 !important;
    border-right: 1px solid #a3a3a3 !important;
    border-bottom: 1px solid #a3a3a3 !important;
  }
}

#sidebar-eventdate .directions-link {
  margin-left: 10px;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
  float: right;
}

#sidebar-eventdate .directions-link:hover {
  color: #1976d2;
}

#sidebar-eventdate .directions-link i {
  font-size: 1.1em;
}
